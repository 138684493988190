import React from 'react';
import DynamicComponent from '@components/DynamicComponent';

export const Row: React.SFC<any> = ({ children, className = '', ...rest }) => (
  <DynamicComponent className={`columns ${className}`} {...rest}>
    { children }
  </DynamicComponent>
);

export const Column: React.SFC<any> = ({ children, className = '', ...rest }) => (
  <DynamicComponent className={`column ${className}`} {...rest}>
    { children }
  </DynamicComponent>
);
