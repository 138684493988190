import React from 'react';
import styled from 'styled-components';

const StyledMainFooter = styled.footer`
  text-align: center;
  color: rgba(255, 255, 255, 0.3);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content:center;
  padding: 10px 0;
  width: 90%;
  margin: 50px auto 0 auto;
  border-top: 2px solid rgba(255, 255, 255, 0.1);  
`;

const StyledMainFooterElement = styled.div`
margin: 10px;

a{
transition: all .3s ease;
  &:hover{
  color:white;
  }
}
  
`;

const MainFooter: React.FC = () => (
  <StyledMainFooter>

    <StyledMainFooterElement>
      <a href="https://www.pixelart.at/de/impressum-agb/">Impressum & AGB</a>
    </StyledMainFooterElement>

    <StyledMainFooterElement>
      <a href="https://www.pixelart.at/de/datenschutzhinweis/">Datenschutzhinweis</a>
    </StyledMainFooterElement>
    <StyledMainFooterElement>
      &copy; 2020 pixelart
    </StyledMainFooterElement>
  </StyledMainFooter>
);

export default MainFooter;
