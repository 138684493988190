import React from 'react';
import { ThemeProvider } from 'styled-components';
import MainHeader from '@components/MainHeader';
import MainFooter from '@components/MainFooter';
import theme from '@/theme';

import '@styles/index.scss';

const Layout: React.SFC = ({ children }) => (
  <ThemeProvider theme={theme}>
    <main>
      <MainHeader />
      { children }
      <MainFooter />
    </main>
  </ThemeProvider>
);

export default Layout;
