import React from 'react';
import styled from 'styled-components';
import {Container} from '@components/Layout';

const StyledMainHeader = styled.header`
display: flex;
justify-content: flex-end;
background: black;
color: white;
text-transform: uppercase;
letter-spacing: 2px;
font-size: 14px;
font-weight: bold;
`;


const MainHeader: React.FC = () => (
  <Container>
    <StyledMainHeader />
  </Container>
);

export default MainHeader;
