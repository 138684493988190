/* stylelint-disable order/properties-alphabetical-order */

import styled from 'styled-components';
import {
  space,
  SpaceProps,
  lineHeight,
  LineHeightProps,
  fontSize,
  FontSizeProps,
  fontStyle,
  FontStyleProps,
  size,
  SizeProps,
  color,
  ColorProps,
  fontFamily,
  FontFamilyProps,
  fontWeight,
  FontWeightProps,
  letterSpacing,
  LetterSpacingProps,
  borderRadius,
  BorderRadiusProps,
  maxWidth,
  MaxWidthProps,
  position,
  PositionProps,
  textAlign,
  TextAlignProps,
  flexbox,
  FlexboxProps,
  layout,
  LayoutProps,
} from 'styled-system';

export type DynamicComponentProps = SpaceProps
& LineHeightProps
& FontSizeProps
& SizeProps
& FontStyleProps
& ColorProps
& FontFamilyProps
& FontWeightProps
& LetterSpacingProps
& BorderRadiusProps
& MaxWidthProps
& PositionProps
& TextAlignProps
& FlexboxProps
& LayoutProps;

export default styled.div<DynamicComponentProps>`
  ${borderRadius}
  ${space}
  ${lineHeight}
  ${fontSize}
  ${fontStyle}
  ${size}
  ${color}
  ${fontFamily}
  ${maxWidth}
  ${fontWeight}
  ${letterSpacing}
  ${position}
  ${textAlign}
  ${flexbox}
  ${layout}
`;
