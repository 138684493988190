import React from 'react';
import styled from 'styled-components';
import DynamicComponent from '@components/DynamicComponent';
import theme from '@/theme';

const StyledSection = styled(DynamicComponent)`
  & + & {
    padding-top: 0;
  }
`;

const Section: React.SFC<{ className?: string}> = ({ children, className = '', ...rest }) => (
  <StyledSection
    as="section"
    className={`section ${className}`}
    {...theme.components.section}
    {...rest}
  >
    { children }
  </StyledSection>
);

export default Section;
