export const colors = {};

const fontFamilies = {
  heading: ['Boston-Regular', 'sans-serif'],
  body: ['Boston-Regular', 'sans-serif'],
};

const breakpoints: any = [
  '769px',
  '1024px',
  '1216px',
  '1408px',
];

breakpoints.xs = '425px';
// eslint-disable-next-line prefer-destructuring
breakpoints.sm = breakpoints[0];
// eslint-disable-next-line prefer-destructuring
breakpoints.md = breakpoints[1];
// eslint-disable-next-line prefer-destructuring
breakpoints.lg = breakpoints[2];
// eslint-disable-next-line prefer-destructuring
breakpoints.xl = breakpoints[3];

export const maxWidths: any = [
  null,
  960,
  1152,
  1344,
];

// eslint-disable-next-line prefer-destructuring
maxWidths.sm = maxWidths[0];
// eslint-disable-next-line prefer-destructuring
maxWidths.md = maxWidths[1];
// eslint-disable-next-line prefer-destructuring
maxWidths.lg = maxWidths[2];
// eslint-disable-next-line prefer-destructuring
maxWidths.xl = maxWidths[3];

export const sectionPaddingsX = ['1rem', '1.5rem', '2rem'];

const theme = {
  textStyles: {
    canon: {
      as: 'h1',
      fontSize: [13, 13, 14],
      fontWeight: 700,
      fontFamily: fontFamilies.heading.join(','),
      lineHeight: [1.35],
      maxWidth: [500, '90vw', '600px'],
      mb: [3, 4],
    },
    trafalgar: {
      as: 'h2',
      fontSize: [9, 10, 11],
      lineHeight: [1.1],
      fontWeight: 500,
      fontFamily: fontFamilies.heading.join(','),
      mb: [3],
    },
    paragon: {
      as: 'h2',
      fontSize: [7, 8, 10],
      fontWeight: 500,
      fontFamily: fontFamilies.heading.join(','),
      lineHeight: ['20px', '26px', '32px'],
      mb: [2, 2, 3],
    },
    doublePica: {
      fontSize: [6, 6, 10],
      fontWeight: 400,
      fontFamily: fontFamilies.heading.join(','),
      lineHeight: ['24px', '24px', '30px'],
    },
    greatPrimer: {
      as: 'h3',
      fontWeight: 400,
      fontSize: [5, 5, 7],
      fontFamily: fontFamilies.heading.join(','),
      lineHeight: ['22px', '22px', '24px'],
    },
    bodyCopy: {
      as: 'p',
      fontSize: 4,
      fontFamily: fontFamilies.body.join(','),
      lineHeight: [1.75],
      maxWidth: maxWidths[1],
    },
    pica: {
      fontSize: [2, 3, 4],
      fontFamilie: fontFamilies.body.join(','),
      lineHeight: ['20px', '20px', '22px'],
    },
  },
  components: {
    section: {
      py: [5, 6],
    },
    sectionWithBg: {
      py: [5, 6, 7],
    },
    container: {
      as: 'div',
      className: 'container',
    },
    imageContainer: {
      height: ['45vw', '20vw'],
    },
    indexRow: {
      padding: ['15px 30px'],
    },
  },
  maxWidths,
  space: [0, 4, 8, 16, 32, 48, 64, 96, 128, 256, 512],
  fontSizes: [12, 13, 14, 15, 16, 18, 20, 21, 22, 24, 26, 28, 32, 36, 52, 68],
  breakpoints,
  colors,
  sectionPaddingsX,
};

export default theme;
