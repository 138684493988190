import React from 'react';
import DynamicComponent from '@components/DynamicComponent';

const Container: React.SFC<{ className?: string}> = ({ children, className = '', ...props }) => (
  <DynamicComponent className={`container ${className}`} {...props}>
    { children }
  </DynamicComponent>
);

export default Container;
